<template>
  <div v-swiper:StaffSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 pt-12 fibre-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'StaffSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        pagination: {
          clickable: true,
          el: '.fibre-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          650: {
            slidesPerView: 4,
            spaceBetween: 20,
            noSwiping: false,
            allowTouchMove: true,
          },
        }
      }
    }
  }
}
</script>
